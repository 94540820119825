export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/build": [9],
		"/dash/[course]": [10],
		"/settings": [11],
		"/[...dir]/[file=asciidoc]": [3],
		"/[...dir]/[file=code]": [4],
		"/[...dir]/[file=markdown]": [5],
		"/[...dir]/[file=pdf]": [6],
		"/[...dir]/[zfile=dir]": [7],
		"/[...dir]/[zfile=generic]": [8]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';